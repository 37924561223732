.nav-trigger {
	display: flex;
	padding: 0;
	border: none;
	background: none;
	background-color: transparent;
	box-shadow: none;
	color: #000;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	
	.txt,
	.bars,
	.bar {
		display: inline-block;
		flex-flow: inherit;
		align-items: inherit;
		justify-content: inherit;
	}

	.bars {
		display: flex;
		flex-flow: column nowrap;
	}

	.bar {
		background-color: #000;
		
		&:before,
		&:after {
			background-color: inherit;
		}
	}
	
	.txt {
		display: inline-block;
		margin-left: 13px;
		font-size: 10px;
		text-transform: uppercase;
		font-weight: 700;
		line-height: 1;
	}


	&.style-1 {
		
		.bars {
			width: 25px;
			height: 13px;
			transform: rotate(90deg);
			transition: all 0.3s 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
		}
		.bar {
			width: 25px;
			height: 1px;
			margin-bottom: 5px;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
			
			&:first-child,
			&:last-child {
				width: 16px;
				opacity: 0;
				visibility: hidden;
			}
			&:first-child {
				transform: translateY(-7px);
			}
			&:last-child {
				margin-bottom: 0;
				transform: translateY(7px);
			}
			&:nth-child(2) {
				transform: rotate(45deg);
				transition-delay: 0.2s;
				transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
				
				&:before {
					content: '';
					display: inline-block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					transition: inherit;
					background-color: inherit;
					transform: rotate(-90deg);					
				}
			}
		}
	}

	&.style-mobile {

		.bars {
			width: 20px;
			height: 15px;
			transform: rotate(0);
			transition: all 0.45s 0.2s cubic-bezier(0.23, 1, 0.320, 1);
		}
		.bar {
			width: 20px;
			height: 2px;
			border-radius: 50em;
			margin-bottom: 4.25px;
			position: relative;
			transition: all 0.45s cubic-bezier(0.23, 1, 0.320, 1);
			
			&:first-child,
			&:last-child {
				opacity: 0;
				visibility: hidden;
				transition: all 0.45s cubic-bezier(0.23, 1, 0.320, 1);
			}
			&:first-child {
				transform: translateY(-3px);
			}
			&:last-child {
				margin-bottom: 0;
				transform: translateY(3px);
			}
			&:nth-child(2) {
				width: 22px;
				margin-right: auto;
				transform: rotate(45deg);
				transition-delay: 0.1s;
				transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
				
				&:before {
					content: '';
					display: inline-block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					border-radius: inherit;
					transition: inherit;
					background-color: inherit;
					transform: rotate(-90deg);					
				}
			}
		}
	}

	&.txt-left {
		flex-direction: row-reverse;

		.txt {
			margin-left: 0;
			margin-right: 13px;
		}
	}

	&.fill-solid {

		.bars {
			width: 42px;
			height: 40px;
			transform: none;
			background-color: rgba(#fff, 0.95);
		}
	}

	&.rotate-90 {
		transform: rotate(-90deg);
	}

	&.scheme-light {
		color: #fff;

		.bar {
			background-color: #fff;
		}
		&.fill-solid {

			.bars {
				background-color: rgba(#000, 0.3);
			}
		}
	}

	&.collapsed {

		.bars {
			transform: none;
			transition-delay: 0s;
		}

		.bar {

			&:first-child,
			&:last-child,
			&:nth-child(2) {
				opacity: 1;
				visibility: visible;
				transform: none;
				transition: all 0.3s ease;

				&:before,
				&:after {
					transform: rotate(0) translate(0, 0);
				}
			}
		}

		&.style-mobile {

			.bar {

				&:first-child,
				&:last-child {
					transition-delay: 0.15s;
				}
				&:nth-child(2) {
					width: 16px;
				}
			}
		}
	}

	&:focus {
		outline: none;
		box-shadow: none;
	}
}