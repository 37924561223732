.ld-module-trigger {
	display: inline-flex;
	align-items: center;
	color: #000;
	cursor: pointer;
	transition: color 0.3s;
}
.ld-module-trigger-icon {
	font-size: 24px;
}
.ld-module-trigger-count {
	display: inline-flex;
	width: 18px;
	height: 18px;
	margin-left: 5px;
	background-color: var(--color-primary);
	border-radius: 50em;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: 700;
	color: #fff;
}