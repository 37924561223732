@mixin placeholder {

  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";

  @each $placeholder in $placeholders {

    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}