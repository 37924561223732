.header-module {

	.iconbox {
		margin-bottom: 0;
		align-items: center !important;

		.iconbox-icon-wrap {
			margin-right: 0;
		}
		.iconbox-icon-container {
			margin-right: 15px;
		}
		h3 {
			margin-bottom: 0.25em;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.iconbox-inline {
		
		h3 {
			flex: auto;
		}
	}
	.iconbox-side {

		.contents {
			flex: auto;
		}
	}
}