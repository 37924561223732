:root {
  --color-font: #808291;
  --color-primary: #EB1C29;
  --color-secondary: #89cff0;
  --color-dark: #1f1f1f;
  --color-link: #a7a9b8;
  --color-link-hover: #181b31;
  --color-gradient-start: #4fda91;
  --color-gradient-stop: #34dbc5;
  --color-img-holder: #efefef;
}