// Including Bootstrap V4 grids. Because it'll make our life easier!
.main-header {
	
	.row {
		display: flex;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-left: -15px;
	}
	
	.no-gutters {
		margin-right: 0;
		margin-left: 0;
	}
	
	.no-gutters>.col,
	.no-gutters>[class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
	.col, .col-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
		position: relative;
		width: 100%;
		min-height: 1px;
		padding-right: 15px;
		padding-left: 15px;
	}
	
	.col {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	
	.col-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
}

@media (min-width: 1200px) {
	
	.main-header {

		.container {
			max-width: 1170px;
		}
	}
}

@media (min-width: 992px) {

	.main-header {
		
		.d-lg-none {
			display: none !important;
		}

		.flex-lg-row {
			-ms-flex-direction: row !important;
			flex-direction: row !important;
		}

		.flex-lg-column {
			-ms-flex-direction: column !important;
			flex-direction: column !important;
		}

		.flex-lg-row-reverse {
			-ms-flex-direction: row-reverse !important;
			flex-direction: row-reverse !important;
		}

		.flex-lg-column-reverse {
			-ms-flex-direction: column-reverse !important;
			flex-direction: column-reverse !important;
		}

		.flex-lg-wrap {
			-ms-flex-wrap: wrap !important;
			flex-wrap: wrap !important;
		}

		.flex-lg-nowrap {
			-ms-flex-wrap: nowrap !important;
			flex-wrap: nowrap !important;
		}

		.flex-lg-wrap-reverse {
			-ms-flex-wrap: wrap-reverse !important;
			flex-wrap: wrap-reverse !important;
		}
		.col-lg {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
		}
		.col-lg-auto {
			flex: 0 0 auto;
			width: auto;
			max-width: none;
		}
		.col-lg-1 {
			flex: 0 0 8.333333%;
			max-width: 8.333333%;
		}
		.col-lg-2 {
			flex: 0 0 16.666667%;
			max-width: 16.666667%;
		}
		.col-lg-3 {
			flex: 0 0 25%;
			max-width: 25%;
		}
		.col-lg-4 {
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
		}
		.col-lg-5 {
			flex: 0 0 41.666667%;
			max-width: 41.666667%;
		}
		.col-lg-6 {
			flex: 0 0 50%;
			max-width: 50%;
		}
		.col-lg-7 {
			flex: 0 0 58.333333%;
			max-width: 58.333333%;
		}
		.col-lg-8 {
			flex: 0 0 66.666667%;
			max-width: 66.666667%;
		}
		.col-lg-9 {
			flex: 0 0 75%;
			max-width: 75%;
		}
		.col-lg-10 {
			flex: 0 0 83.333333%;
			max-width: 83.333333%;
		}
		.col-lg-11 {
			flex: 0 0 91.666667%;
			max-width: 91.666667%;
		}
		.col-lg-12 {
			flex: 0 0 100%;
			max-width: 100%;
		}
		.order-lg-first {
			order: -1;
		}
		.order-lg-1 {
			order: 1;
		}
		.order-lg-2 {
			order: 2;
		}
		.order-lg-3 {
			order: 3;
		}
		.order-lg-4 {
			order: 4;
		}
		.order-lg-5 {
			order: 5;
		}
		.order-lg-6 {
			order: 6;
		}
		.order-lg-7 {
			order: 7;
		}
		.order-lg-8 {
			order: 8;
		}
		.order-lg-9 {
			order: 9;
		}
		.order-lg-10 {
			order: 10;
		}
		.order-lg-11 {
			order: 11;
		}
		.order-lg-12 {
			order: 12;
		}
		.offset-lg-0 {
			margin-left: 0;
		}
		.offset-lg-1 {
			margin-left: 8.333333%;
		}
		.offset-lg-2 {
			margin-left: 16.666667%;
		}
		.offset-lg-3 {
			margin-left: 25%;
		}
		.offset-lg-4 {
			margin-left: 33.333333%;
		}
		.offset-lg-5 {
			margin-left: 41.666667%;
		}
		.offset-lg-6 {
			margin-left: 50%;
		}
		.offset-lg-7 {
			margin-left: 58.333333%;
		}
		.offset-lg-8 {
			margin-left: 66.666667%;
		}
		.offset-lg-9 {
			margin-left: 75%;
		}
		.offset-lg-10 {
			margin-left: 83.333333%;
		}
		.offset-lg-11 {
			margin-left: 91.666667%;
		}
	}
}

@media (min-width: 1200px) {

	.main-header {
		
		.d-xl-none {
			display: none !important;
		}

		.flex-xl-row {
			-ms-flex-direction: row !important;
			flex-direction: row !important;
		}

		.flex-xl-column {
			-ms-flex-direction: column !important;
			flex-direction: column !important;
		}

		.flex-xl-row-reverse {
			-ms-flex-direction: row-reverse !important;
			flex-direction: row-reverse !important;
		}

		.flex-xl-column-reverse {
			-ms-flex-direction: column-reverse !important;
			flex-direction: column-reverse !important;
		}

		.flex-xl-wrap {
			-ms-flex-wrap: wrap !important;
			flex-wrap: wrap !important;
		}

		.flex-xl-nowrap {
			-ms-flex-wrap: nowrap !important;
			flex-wrap: nowrap !important;
		}

		.flex-xl-wrap-reverse {
			-ms-flex-wrap: wrap-reverse !important;
			flex-wrap: wrap-reverse !important;
		}
		.col-xl {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
		}
		.col-xl-auto {
			flex: 0 0 auto;
			width: auto;
			max-width: none;
		}
		.col-xl-1 {
			flex: 0 0 8.333333%;
			max-width: 8.333333%;
		}
		.col-xl-2 {
			flex: 0 0 16.666667%;
			max-width: 16.666667%;
		}
		.col-xl-3 {
			flex: 0 0 25%;
			max-width: 25%;
		}
		.col-xl-4 {
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
		}
		.col-xl-5 {
			flex: 0 0 41.666667%;
			max-width: 41.666667%;
		}
		.col-xl-6 {
			flex: 0 0 50%;
			max-width: 50%;
		}
		.col-xl-7 {
			flex: 0 0 58.333333%;
			max-width: 58.333333%;
		}
		.col-xl-8 {
			flex: 0 0 66.666667%;
			max-width: 66.666667%;
		}
		.col-xl-9 {
			flex: 0 0 75%;
			max-width: 75%;
		}
		.col-xl-10 {
			flex: 0 0 83.333333%;
			max-width: 83.333333%;
		}
		.col-xl-11 {
			flex: 0 0 91.666667%;
			max-width: 91.666667%;
		}
		.col-xl-12 {
			flex: 0 0 100%;
			max-width: 100%;
		}
		.order-xl-first {
			order: -1;
		}
		.order-xl-1 {
			order: 1;
		}
		.order-xl-2 {
			order: 2;
		}
		.order-xl-3 {
			order: 3;
		}
		.order-xl-4 {
			order: 4;
		}
		.order-xl-5 {
			order: 5;
		}
		.order-xl-6 {
			order: 6;
		}
		.order-xl-7 {
			order: 7;
		}
		.order-xl-8 {
			order: 8;
		}
		.order-xl-9 {
			order: 9;
		}
		.order-xl-10 {
			order: 10;
		}
		.order-xl-11 {
			order: 11;
		}
		.order-xl-12 {
			order: 12;
		}
		.offset-xl-0 {
			margin-left: 0;
		}
		.offset-xl-1 {
			margin-left: 8.333333%;
		}
		.offset-xl-2 {
			margin-left: 16.666667%;
		}
		.offset-xl-3 {
			margin-left: 25%;
		}
		.offset-xl-4 {
			margin-left: 33.333333%;
		}
		.offset-xl-5 {
			margin-left: 41.666667%;
		}
		.offset-xl-6 {
			margin-left: 50%;
		}
		.offset-xl-7 {
			margin-left: 58.333333%;
		}
		.offset-xl-8 {
			margin-left: 66.666667%;
		}
		.offset-xl-9 {
			margin-left: 75%;
		}
		.offset-xl-10 {
			margin-left: 83.333333%;
		}
		.offset-xl-11 {
			margin-left: 91.666667%;
		}
	}
}

@media (min-width: 992px) {

	.main-header {
		
		.flex-lg-row-reverse {
			flex-direction: row-reverse !important;
		}
		.flex-lg-column-reverse {
			flex-direction: column-reverse !important;
		}
		.flex-lg-wrap {
			flex-wrap: wrap !important;
		}
		.flex-lg-nowrap {
			flex-wrap: nowrap !important;
		}
		.flex-lg-wrap-reverse {
			flex-wrap: wrap-reverse !important;
		}
	}
}

@media (min-width: 1200px) {

	.main-header {
		
		.flex-xl-row-reverse {
			flex-direction: row-reverse !important;
		}
		.flex-xl-column-reverse {
			flex-direction: column-reverse !important;
		}
		.flex-xl-wrap {
			flex-wrap: wrap !important;
		}
		.flex-xl-nowrap {
			flex-wrap: nowrap !important;
		}
		.flex-xl-wrap-reverse {
			flex-wrap: wrap-reverse !important;
		}
	}
}