.lqd-section-borders-wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	visibility: hidden;
	opacity: 0;

	&.sticky-applied {
		opacity: 1;
		visibility: visible;
	}
}
.lqd-section-border-sentinel {
	top: 0;
	left: 0;
	width: 100%;
	min-height: 1px;
	z-index: -1;
}
.lqd-section-border {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background-color: #fff;

	&[data-axis=x] {
		width: 4.69vw;
		height: 100%;
	}
	&[data-axis=y] {
		width: 100%;
		height: 4.69vw;
	}
}
.lqd-section-border-top {
	transform-origin: center top;
}
.lqd-section-border-right {
	left: auto;
	right: 0;
	transform-origin: right center;
}
.lqd-section-border-bottom {
	top: auto;
	bottom: 0;
	transform-origin: center bottom;
}
.lqd-section-border-left {
	transform-origin: left center;
}